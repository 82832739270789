<template>
  <div class="client-detail-admin">
    <div class="left-side column-align">
      <div
        v-if="$can(clientAccess.BLOCK_CLIENT, clientAccess.moduleName)"
        class="card p-4 rounded-lg account"
      >
        <card-detail-account component="clients" :status.sync="user.status" />
      </div>
      <div class="card p-4 rounded-lg info">
        <card-admin-info :user="user" />
      </div>
    </div>
    <div class="right-side column-align">
      <div v-if="!isCompany" class="card p-4 rounded-lg branch">
        <card-admin-branch
          :address-id="userAddress"
          :type.sync="user.type"
          :company-id.sync="user.companyId"
          :phone="user.phone"
        />
      </div>
      <div class="card p-4 rounded-lg rate ">
        <card-detail-enabled-services
          :id="user.id"
          :enabled-services="user.enabledServices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClientAccess from "@/constants/access/clients";
import Type from "@zubut/common/src/constants/clients/type";
import CardDetailAccount from "@/app/components/DetailProfile/CardDetailAccount";
import CardAdminInfo from "@/app/components/DetailProfile/CardAdminInfo";
import CardAdminBranch from "@/app/components/DetailProfile/CardAdminBranch";
import CardDetailEnabledServices from "@/app/components/DetailProfile/CardDetailEnabledServices";

export default {
  name: "ClientDetailAdmin",

  components: {
    CardAdminInfo,
    CardDetailAccount,
    CardAdminBranch,
    CardDetailEnabledServices
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      clientAccess: ClientAccess
    };
  },

  computed: {
    isCompany() {
      return Type.NUM_COMPANY === this.user?.type || 0;
    },
    userAddress() {
      return this.user?.addresses[0]?.id || null;
    }
  },

  methods: {
    updateEnabledServices(enabledServices) {
      this.loading = true;
      Clients.editUser(this.id, { enabledServices: [...enabledServices] })
        .then(() => {
          this.quickMessage = {
            message: "El tipo de entrega se actualizó con éxito",
            type: "success"
          };
        })
        .catch(err => {
          this.quickMessage = {
            message:
              "Ocurrió un error al intentar habilitar tus entregas. Vuelve a intentar más tarde o contacta a soporte.",
            type: "error"
          };
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content .card {
  margin: 0;
}

.client-detail-admin {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.column-align {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.left-side {
  flex-grow: 0.5;
}
.right-side {
  flex-grow: 1;
}

@media only screen and (min-width: 860px) {
  .client-detail-admin {
    flex-direction: row;
    gap: 25px;
  }
}
</style>
