<template>
  <div class="d-flex h-100 flex-column">
    <p>Sucursal</p>
    <div v-show="!createAddress">
      <b-form-checkbox v-model="checkBranch" :disabled="!editMode && isBranch">
        <span class="text">Este usuario es una sucursal</span>
      </b-form-checkbox>
      <b-form-group
        v-if="isBranch"
        id="parent-company"
        label="Compañía"
        label-size="sm"
        class="mt-4"
      >
        <z-dropdown-clients
          v-model="parentCompany"
          :disabled="!editMode"
          name="Compañía"
          :client-types="clientTypes"
          :first-option="firstOption"
          :exclude="excludeMe"
          no-flip
        />
      </b-form-group>
    </div>
    <form-default-address
      v-if="createAddress"
      @address-change="handleAddressChange"
      @references-change="handleReferencesChange"
    />
    <div
      v-if="$can(clientAccess.EDIT_CLIENT_ADMIN_DATA, clientAccess.moduleName)"
      class="mt-3 w-100 d-flex flex-column flex-sm-row "
    >
      <z-button
        v-show="!editMode && isBranch"
        class="z-action w-auto"
        @click="editMode = true"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode && isBranch"
        variant="secondary"
        class="z-action w-auto mr-0 mr-sm-3"
        @click="clearData"
      >
        Cancelar
      </z-button>
      <z-button
        v-show="editMode && isBranch && hasAddress"
        :disabled="!validData"
        class="z-action w-auto mt-3 mt-sm-0"
        @click="dispatchUpdate"
      >
        Guardar cambios
      </z-button>
      <z-button
        v-show="editMode && isBranch && !hasAddress"
        :disabled="!validData"
        class="z-action w-auto mt-3 mt-sm-0"
        @click="showCreateAddress"
      >
        Continuar
      </z-button>
    </div>
    <quick-message
      class="mt-2"
      with-icon
      :show.sync="hasMessage"
      :type="hasErrors ? 'error' : 'success'"
      :message="message"
    />
  </div>
</template>

<script>
import ClientAccess from "@/constants/access/clients";
import Type from "@zubut/common/src/constants/clients/type";
import ZDropdownClients from "@/app/components/ZDropdownClients";
import FormDefaultAddress from "@/app/components/FormDefaultAddress";

export default {
  name: "CardAdminBranch",
  components: {
    ZDropdownClients,
    FormDefaultAddress
  },
  props: {
    companyId: {
      type: String,
      default: null
    },
    type: {
      type: Number,
      default: 0
    },
    addressId: {
      type: String,
      default: null
    },
    phone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      clientAccess: ClientAccess,
      isLoading: false,
      hasErrors: false,
      isSuccess: false,
      editMode: false,
      message: "",
      parentCompany: this.companyId,
      checkBranch: this.companyId != null,
      createAddress: false,
      draftAddress: null,
      draftReference: "",
      clientTypes: [Type.NUM_CLIENT, Type.NUM_COMPANY],
      firstOption: { text: "Ninguna", value: null },
      excludeMe: [this.$route.params.id]
    };
  },
  computed: {
    hasMessage: {
      get() {
        return this.hasErrors || this.isSuccess;
      },
      set(newVal) {
        this.hasErrors = newVal;
        this.isSuccess = newVal;
      }
    },
    isBranch() {
      return this.companyId != null || this.checkBranch;
    },
    validData() {
      return (
        this.companyId !== this.parentCompany && this.parentCompany != null
      );
    },
    hasAddress() {
      return (
        this.addressId != null ||
        (this.draftAddress?.address != null &&
          this.draftAddress?.position != null &&
          this.draftAddress?.position?.lat != null &&
          this.draftAddress?.position?.lng != null)
      );
    }
  },
  watch: {
    checkBranch(newVal) {
      if (!newVal) {
        this.parentCompany = null;
      } else {
        this.parentCompany = this.companyId;
      }

      if (newVal && this.companyId == null) {
        this.editMode = true;
      }
    }
  },

  methods: {
    clearData() {
      this.checkBranch = this.companyId != null;
      this.parentCompany = this.companyId;
      this.editMode = false;
      this.createAddress = false;
      this.draftAddress = null;
    },

    dispatchUpdate() {
      if (this.checkBranch) {
        this.updateParentCompany();
      } else {
        this.unlinkBranch();
      }
    },

    showCreateAddress() {
      this.createAddress = true;
    },

    handleAddressChange(address) {
      this.draftAddress = { ...address };
    },

    handleReferencesChange(references) {
      this.draftReference = references;
    },

    updateParentCompany() {
      const data = {};
      if (this.hasAddress) {
        if (this.addressId != null) {
          data.addressId = this.addressId;
        } else {
          data.phone = this.phone;
          data.address = this.draftAddress.address;
          data.position = this.draftAddress.position;
          data.information =
            this.draftReference != "" ? this.draftReference : undefined;
        }
      }
      data.branchId = this.$route.params.id;
      this.$store
        .dispatch("clients/addBranch", {
          companyId: this.parentCompany,
          data
        })
        .then(() => {
          this.$emit("update:type", Type.NUM_BRANCH);
          this.$emit("update:companyId", this.parentCompany);
          this.message = `Se vinculó la sucursal de manera exitosa`;
          this.isSuccess = true;
          this.editMode = false;
          this.createAddress = false;
        })
        .catch(err => {
          if (err.message) {
            this.message = err.message;
          } else {
            this.message = `Error al vincular la sucursal a la compañia.`;
          }
          this.hasErrors = true;
          this.$captureError(err);
        });
    },

    unlinkBranch() {
      const id = this.$route.params.id;
      const data = {
        type: Type.NUM_CLIENT,
        companyId: null
      };
      this.$store
        .dispatch(`clients/updateUser`, { id, data })
        .then(() => {
          this.$emit("update:type", Type.NUM_CLIENT);
          this.$emit("update:companyId", null);
          this.message = `Se devinculó la sucursal de manera exitosa`;
          this.isSuccess = true;
          this.editMode = false;
        })
        .catch(err => {
          if (err.message) {
            this.message = err.message;
          } else {
            this.message = `Error al desvincular la sucursal de la compañia.`;
          }
          this.hasErrors = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
p:first-child {
  font-size: 14px;
  font-weight: 600;
}

.z-action {
  min-height: 40px;
  font-weight: 500;
}

.text {
  font-size: 12px;

  &.info {
    color: $dim-gray;
    margin-top: auto;
  }
}
</style>
