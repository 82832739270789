<template>
  <div class="h-100 flex-column justify-content-between">
    <quick-message
      :message="quickMessage ? quickMessage.message : ''"
      :show.sync="showQuickMessage"
      :type="quickMessage ? quickMessage.type : 'success'"
      with-icon
      class="mb-2"
    />
    <b-form class="form">
      <p>Información</p>
      <z-select
        v-model="userMutable.businessLine"
        :disabled="!editMode"
        :options="businessLine"
        label="Giro de empresa"
        class="my-4"
      />

      <z-select
        v-model="userMutable.conversionChannel"
        :disabled="!editMode"
        :options="conversionChannelOptions"
        label="Canal de adquisición"
        class="mb-4"
      />
      <hr />
      <div class="my-4 d-flex flex-column flex-md-row justify-content-between">
        <b-form-checkbox
          v-model="userMutable.distinguished"
          class="mr-md-3"
          :disabled="!editMode"
        >
          Usuario Distinguido
        </b-form-checkbox>
        <b-form-checkbox
          v-model="userMutable.zoneBoundaries"
          class="mr-md-3"
          :disabled="!editMode"
        >
          Restricción de Zona
        </b-form-checkbox>
        <b-form-checkbox v-model="specialSchedule">
          Habilitar horario especial
        </b-form-checkbox>
      </div>
      <hr />
      <div class="d-flex justify-content-between flex-column flex-sm-row my-4">
        <b-form-group
          class="flex-grow-1 m-0 mr-sm-2 font-weight-bold"
          label="Color primario"
          label-for="user-primary-color"
          label-size="sm"
        >
          <b-form-input
            id="user-primary-color"
            v-model="userMutable.mainColor"
            v-validate="{ regex: /^#([0-9a-f]{6})$/i }"
            class="upperSide"
            name="primary-color"
            :disabled="!editMode"
            data-vv-as="color primario"
            :state="validateState('primary-color')"
          />

          <div class="color-picker-input">
            <input
              id="user-primary-color-picker"
              ref="mainColor"
              v-model="userMutable.mainColor"
              class="downSide form-control"
              :disabled="!editMode"
              type="color"
            />
            <z-icon
              name="Dropdown"
              :size="16"
              @click.native="clickIcon('mainColor')"
            />
          </div>

          <span class="invalid-feedback d-block text-left">
            {{ veeErrors.first("primary-color") }}
          </span>
        </b-form-group>

        <b-form-group
          class="flex-grow-1 m-0 mt-2 mt-sm-0 ml-sm-2 font-weight-bold"
          label="Color secundario"
          label-for="user-secondary-color"
          label-size="sm"
        >
          <b-form-input
            id="user-secondary-color"
            v-model="userMutable.secondaryColor"
            v-validate="{ regex: /^#([0-9a-f]{6})$/i }"
            class="upperSide"
            name="seconday-color"
            :disabled="!editMode"
            :state="validateState('seconday-color')"
            data-vv-as="color secundario"
          />

          <div class="color-picker-input">
            <input
              id="user-secondary-color-picker"
              ref="secondaryColor"
              v-model="userMutable.secondaryColor"
              class="downSide form-control"
              :disabled="!editMode"
              type="color"
            />
            <z-icon
              name="Dropdown"
              :size="16"
              @click.native="clickIcon('secondaryColor')"
            />
          </div>
          <span class="invalid-feedback d-block text-left">
            {{ veeErrors.first("seconday-color") }}
          </span>
        </b-form-group>
      </div>
      <quick-message
        with-icon
        :show.sync="hasMessage"
        :message="message"
        :type="hasError ? 'error' : 'success'"
      />
    </b-form>
    <div
      v-if="$can(clientAccess.EDIT_CLIENT_ADMIN_DATA, clientAccess.moduleName)"
      class="actions mt-4 w-100 d-flex flex-column flex-sm-row"
    >
      <z-button
        v-show="!editMode"
        class="z-action w-auto"
        @click="editMode = !editMode"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode"
        variant="secondary"
        class="z-action w-auto mr-0 mr-sm-3"
        @click="cancelEdit"
      >
        Cancelar
      </z-button>
      <z-button
        v-show="editMode"
        class="z-action w-auto mt-3 mt-sm-0 "
        @click="saveAdminChanges"
      >
        Guardar cambios
      </z-button>
    </div>
  </div>
</template>

<script>
import ClientAccess from "@/constants/access/clients";
import BusinessLine from "@/constants/clients/businessLine";
import ConversionChannel from "@/constants/clients/conversion-channel";
import Clients from "@/services/clients";
import ZSelect from "@zubut/common/src/components/ZSelect.vue";

export default {
  name: "CardAdminInfo",

  components: { ZSelect },

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      clientAccess: ClientAccess,
      specialSchedule: false,
      editMode: false,
      disabled: false,
      businessLine: BusinessLine.options,
      conversionChannel: null,
      conversionChannelOptions: ConversionChannel.options,
      userMutable: this.cloneUser(this.user),
      hasError: false,
      isSuccess: false,
      message: "",
      quickMessage: {
        message: "",
        type: "success"
      },
      showQuickMessage: false
    };
  },
  computed: {
    isFormValid() {
      const invalidFields = Object.keys(this.veeFields).some(
        key => this.veeFields[key].invalid
      );
      return !invalidFields;
    },
    hasMessage: {
      get() {
        return this.hasError || this.isSuccess;
      },
      set(newVal) {
        this.hasError = newVal;
        this.isSuccess = newVal;
      }
    }
  },
  watch: {
    user() {
      this.userMutable = this.cloneUser(this.user);
    },

    specialSchedule() {
      this.handleSpecialSchedule();
    }
  },

  mounted() {
    if (this.userMutable.additional?.specialSchedule) {
      this.specialSchedule = true;
    }
    if (this.userMutable.businessLine === BusinessLine.NUM_BUSINESS_LOCAL)
      this.userMutable.businessLine = BusinessLine.NUM_OTHER;
  },
  methods: {
    clickIcon(refName) {
      if (this.editMode) {
        this.$refs[refName].click();
      }
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    cancelEdit() {
      this.editMode = !this.editMode;
      this.userMutable = this.cloneUser(this.user);
      this.$validator.reset();
      if (this.userMutable.businessLine === BusinessLine.NUM_BUSINESS_LOCAL) {
        this.userMutable.businessLine = BusinessLine.NUM_OTHER;
      }
    },

    getServicesToCallOnSave() {
      const services = [];

      if (this.userMutable.distinguished != this.user.distinguished)
        services.push(this.setDistinguished());
      if (
        this.userMutable.businessLine != this.user.businessLine ||
        this.userMutable.mainColor != this.user.mainColor ||
        this.userMutable.secondaryColor != this.user.secondaryColor ||
        this.userMutable.zoneBoundaries != this.user.zoneBoundaries ||
        this.userMutable.conversionChannel != this.user.conversionChannel
      )
        services.push(this.setAdminProperties());

      return services;
    },

    saveAdminChanges() {
      Promise.all(this.getServicesToCallOnSave())
        .then(() => {
          this.$emit("updated", this.userMutable);
          this.message = "Cambio realizado exitosamente.";
          this.isSuccess = true;
        })
        .catch(err => {
          this.$captureError(err);
          this.message = "No se ha podido realizar el cambio.";
          this.hasError = true;
        })
        .finally(() => {
          this.editMode = false;
        });
    },

    setAdminProperties() {
      const userData = { clientId: this.userMutable.id };
      userData.businessLine = this.userMutable.businessLine;
      userData.conversionChannel = this.userMutable.conversionChannel;
      userData.zoneBoundaries = this.userMutable.zoneBoundaries;
      userData.mainColor = this.userMutable.mainColor;
      userData.secondaryColor = this.userMutable.secondaryColor;
      return new Promise((resolve, reject) => {
        Clients.updateAdminProperties(userData)
          .then(
            () => {
              resolve();
            },
            err => {
              reject(err);
            }
          )
          .catch(err => {
            this.$captureError(err);
            reject(err);
          });
      });
    },

    setDistinguished() {
      return new Promise((resolve, reject) => {
        Clients.setDistinguished(
          this.userMutable.id,
          this.userMutable.distinguished
        )
          .then(
            () => {
              resolve();
            },
            err => {
              reject(err);
            }
          )
          .catch(err => {
            this.$captureError(err);
            reject(err);
          });
      });
    },

    cloneUser(user) {
      let clonedUser = { ...user };
      if (!clonedUser.mainColor) {
        clonedUser.mainColor = "#000000";
      }
      if (!clonedUser.secondaryColor) {
        clonedUser.secondaryColor = "#000000";
      }
      return clonedUser;
    },

    handleSpecialSchedule() {
      if (
        this.user.additional &&
        this.user.additional.specialSchedule === this.specialSchedule
      ) {
        return;
      }
      const data = {};
      data.additional = this.userMutable.additional
        ? { ...this.userMutable.additional }
        : {};
      data.additional.specialSchedule = this.specialSchedule;
      Clients.editUser(this.user.id, data)
        .then(res => {
          if (this.user.additional) {
            this.user.additional.specialSchedule = this.specialSchedule;
          } else this.user.additional = data;
          this.quickMessage.message = "Horario especial actulizado con éxito";
          this.quickMessage.type = "success";
        })
        .catch(err => {
          this.quickMessage.message = "Error al actualizar horario especial";
          this.quickMessage.type = "error";
          this.specialSchedule = !specialSchedule;
          this.$captureError(err);
        })
        .finally(() => (this.showQuickMessage = true));
    }
  }
};
</script>

<style lang="scss" scoped>
.color-picker-input {
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 13px;
  }
}
.upperSide {
  border-radius: 6px 6px 0 0;
}
.color-picker-input .downSide {
  border-radius: 0 0 6px 6px;
  border-top: 0;
  padding: 3px 35px 3px 7px;
}
hr {
  border-color: $link-water;
}

p:first-child {
  font-size: 14px;
  font-weight: 500;
}

.z-action {
  width: 100px;
  font-weight: 500;
  height: 40px;
}

.form {
  ::v-deep .col-form-label-sm {
    font-size: 12px;
    margin-bottom: 4px;
    padding: 0;
  }

  ::v-deep .form-control::placeholder {
    font-size: 12px;
    color: $dim-gray;
  }

  ::v-deep .custom-control-label {
    font-size: 12px;
  }

  input {
    max-width: 460px;
    border: 1px solid $gainsboro;
  }

  select {
    max-width: 460px;
    border: 1px solid $gainsboro;
  }
}

.custom-control.custom-checkbox {
  line-height: 21px;
}
</style>
