<template>
  <div class="h-100 d-flex justify-content-around flex-column">
    <div class="d-flex justify-content-between">
      <p>Cuenta</p>
      <b-form-checkbox
        :key="componentKey"
        switch
        size="lg"
        :checked="isActive"
        :disabled="isLoading || !confirmedEmail"
        @input="changeStatus"
      >
        <b-spinner v-show="isLoading" small variant="primary" type="grow" />
        <div v-show="!isLoading">{{ statusName }}</div>
      </b-form-checkbox>
    </div>
    <p class="text info align-middle">
      Activa o bloquea la cuenta en el momento en que lo desees
    </p>
    <quick-message
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="hasErrors ? 'error' : 'success'"
    />
    <quick-message
      v-if="!confirmedEmail"
      class="mt-3"
      show
      icon-name="envelope"
    >
      <template #content>
        <div class="d-flex align-items-center flex-grow-1">
          <font-awesome-icon icon="envelope" />
          <p class="message-text">
            La cuenta no ha sido activada
          </p>
        </div>
        <div
          class="d-flex justify-content-around flex-grow-1 mt-3 mt-sm-0 ml-0 ml-sm-auto"
        >
          <a @click="activeAccount">Activar cuenta</a>
          <a class="ml-2">Reenviar correo</a>
        </div>
      </template>
    </quick-message>
  </div>
</template>

<script>
import clientStatus from "@/constants/clients/status";
import driverStatus from "@/constants/drivers/status";
import adminStatus from "@/constants/admins/status";

const CLIENT = "clients";
const DRIVER = "drivers";
const ADMIN = "admin";

export default {
  name: "CardDetailAccount",

  props: {
    component: {
      type: String,
      required: true,
      validator: value => {
        return ["clients", "admin", "drivers"].indexOf(value) !== -1;
      }
    },
    status: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      statusConts: this.defineConstants(),
      isLoading: false,
      hasErrors: false,
      isSuccess: false,
      componentKey: 0,
      message: ""
    };
  },

  computed: {
    isClient() {
      return this.component === CLIENT;
    },
    isAdmin() {
      return this.component === ADMIN;
    },
    isDriver() {
      return this.component === DRIVER;
    },
    statusName() {
      return this.statusConts.get[this.status];
    },
    isActive: {
      get() {
        return this.status === this.statusConts.NUM_ACTIVE;
      },
      set(newValue) {}
    },
    confirmedEmail() {
      if (this.isClient || this.isAdmin || this.isDriver)
        return (
          this.status !== this.statusConts.NUM_PENDING &&
          this.status !== this.statusConts.NUM_NEW
        );
      else return true;
    },
    hasMessage: {
      get() {
        return this.hasErrors || this.isSuccess;
      },
      set(newVal) {
        this.hasErrors = newVal;
        this.isSuccess = newVal;
      }
    }
  },

  methods: {
    defineConstants() {
      switch (this.component) {
        case ADMIN:
          return adminStatus;
        case CLIENT:
          return clientStatus;
        case DRIVER:
          return driverStatus;
        default:
          return clientStatus;
      }
    },
    changeStatus() {
      const status =
        this.status === this.statusConts.NUM_ACTIVE
          ? this.statusConts.NUM_BLOCKED
          : this.statusConts.NUM_ACTIVE;
      this.isLoading = true;
      this.$store
        .dispatch(`${this.component}/updateStatus`, {
          status,
          id: this.$route.params.id
        })
        .then(() => {
          this.$emit("update:status", status);
          this.message = `Cuenta ${
            status === this.statusConts.NUM_ACTIVE ? "activada" : "bloqueada"
          }`;
          this.isSuccess = true;
        })
        .catch(err => {
          /**
           * This is to force the update of the component
           * and to return it to original state
           */
          this.componentKey += 1;
          this.message = `Error al activar la cuenta. HTTP ${err.statusCode}`;
          this.hasErrors = true;
          this.$captureError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    activeAccount() {
      this.isLoading = true;
      const id = this.$route.params.id;
      const status = this.statusConts.NUM_ACTIVE;
      this.$store
        .dispatch(`${this.component}/activate`, id)
        .then(() => {
          this.$emit("update:status", status);
          this.message = "Cuenta activada";
          this.componentKey += 1;
          this.isSuccess = true;
        })
        .catch(err => {
          this.$captureError(err);
          this.message = `Error al activar la cuenta. HTTP ${err.statusCode}`;
          this.hasErrors = true;
          this.$captureError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/bootstrap/alert.scss";

p:first-child {
  font-size: 14px;
  font-weight: 600;
}

.text {
  font-size: 12px;

  &.info {
    color: $dim-gray;
  }
}

.message-text {
  padding: 0;
  margin: 0;
  margin-left: 1rem;
}

a {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

::v-deep .custom-switch.b-custom-control-lg .custom-control-label {
  font-size: 12px;
  line-height: 2;
}
</style>
