const ACTIVE = "Activo";
const BLOCKED = "Bloqueado";

const NUM_ACTIVE = 0;
const NUM_BLOCKED = 1;

const get = {};
get[NUM_ACTIVE] = ACTIVE;
get[NUM_BLOCKED] = BLOCKED;

export default {
  get,
  ACTIVE,
  BLOCKED,
  NUM_ACTIVE,
  NUM_BLOCKED
};
